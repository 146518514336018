import React, { ForwardedRef, forwardRef } from "react";
import { Col } from "react-bootstrap";

interface ImageTickerSliderProps {
  isListBiggerThanContainer: boolean;
  sideShadowColor: string;
  sideShadowColorAsRGB: string;
  children: React.ReactNode;
}

const ImageTickerSlider = forwardRef(
  (props: ImageTickerSliderProps, ref: ForwardedRef<HTMLDivElement>) => {
    return (
      <Col
        ref={ref}
        xs={12}
        className={
          props.isListBiggerThanContainer
            ? "image-ticker-wrapper"
            : "image-ticker-wrapper center-list"
        }
      >
        {props.isListBiggerThanContainer && (
          <div
            className={"shadowLeft"}
            style={{
              backgroundImage: `linear-gradient(to right, ${props.sideShadowColor} 10%, rgba(${props.sideShadowColorAsRGB}, 0.7) 50%, rgba(${props.sideShadowColorAsRGB}, 0) 100%)`,
            }}
          ></div>
        )}
        {props.children}
        {props.isListBiggerThanContainer && (
          <div
            className={"shadowRight"}
            style={{
              backgroundImage: `linear-gradient(to left, ${props.sideShadowColor} 10%, rgba(${props.sideShadowColorAsRGB}, 0.7) 50%, rgba(${props.sideShadowColorAsRGB}, 0) 100%)`,
            }}
          ></div>
        )}
      </Col>
    );
  }
);

ImageTickerSlider.displayName = "ImageTickerSlider";
export default ImageTickerSlider;
