import CmsAddButton from "@/components/cms/cmsAddButton/cmsAddButton";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import useScopedClassName from "@/hooks/useScopedClassName";
import useStrColor from "@/hooks/useStrColor";
import useWindowSize from "@/hooks/useWindowSize";
import { showNestedContentModal } from "@/store/slices/cmsGeneral/cmsGeneralSlice";
import { useAppDispatch, useAppSelector } from "@/store/store";
import { ContentElementImageTickerStoreSetting } from "@/types/ceSettings/ceSettings";
import { CEImageTicker } from "@/types/content-elements";
import { getCssVariableValue, hexToRgb } from "@/utils/util";
import clsx from "clsx";
import { CSSProperties, useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import ContentElementWrapper from "../wrapper/contentElementWrapper";
import ImageTickerContentElementGlobalStyle from "./imageTickerContentElementGlobalStyle";
import ImageTickerContentElementStyleScoped from "./imageTickerContentElementScopedStyle";
import ImageTickerSlider from "./imageTickerSlider";
import ImageTickerSliderList from "./imageTickerSliderList";
import ImageTickerStaticImages from "./imageTickerStaticImages";

export interface ImageTickerContentElementProps {
  content: CEImageTicker;
  position: number;
  ceSettings?: ContentElementImageTickerStoreSetting;
  isLastElement: boolean;
  isMobile: boolean;
  isFirstElement: boolean;
}
export const IMAGETICKER_CE_NAME = "imageTickerCE";

const ImageTickerContentElement = (props: ImageTickerContentElementProps) => {
  const tickerContent = props.content;
  const tCms = useCmsTranslation();
  const windowSize = useWindowSize();
  const [isListBiggerThanContainer, setIsListBiggerThanContainer] =
    useState<boolean>(false);
  const [animationSpeed, setAnimationSpeed] = useState<CSSProperties>({});
  const [sliderListWidth, setSliderListWidth] = useState<number>();
  const listContainer = useRef<HTMLDivElement>(null);
  const sliderList = useRef<HTMLUListElement>(null);

  const { colorCssVar: sideShadowColorCssVar } = useStrColor(
    props.content.cfgStrSideShadowColor,
    props.ceSettings?.sideShadowColors
  );
  const { colorCssVar: backgroundColorCssVar } = useStrColor(
    props.content.cfgStrBackgroundColor,
    props.ceSettings?.bgColors
  );

  const IMAGETICKER_SCOPED_CLASS = useScopedClassName(
    IMAGETICKER_CE_NAME,
    props.content,
    props.position
  );

  const editView = useAppSelector((state) => state.cmsGeneral.editView);
  const speedScalingFactor = props.ceSettings?.slideSpeedScalingFactor
    ? props.ceSettings.slideSpeedScalingFactor * 10
    : 50;
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (sliderListWidth) {
      if (props.content.cfgIsStatic) {
        setIsListBiggerThanContainer(false);
      }
      if (listContainer.current && !props.content.cfgIsStatic) {
        setIsListBiggerThanContainer(
          listContainer.current.clientWidth < sliderListWidth
        );
      }
      setAnimationSpeed({
        animationDuration: sliderListWidth / speedScalingFactor + "s",
        MozAnimationDuration: sliderListWidth / speedScalingFactor + "s",
      });
    }
  }, [
    windowSize,
    sliderListWidth,
    props.content.cfgIsStatic,
    speedScalingFactor,
    props.content.content,
  ]);

  useEffect(() => {
    if (
      sliderList.current &&
      sliderListWidth !== sliderList.current.getBoundingClientRect().width
    ) {
      setSliderListWidth(sliderList.current.getBoundingClientRect().width);
    }
  }, [sliderListWidth, windowSize, props.content.content]);

  const loadListWidth = () => {
    if (sliderList.current) {
      setSliderListWidth(sliderList.current.getBoundingClientRect().width);
    }
  };

  const getSideShadowColor = () => {
    if (sideShadowColorCssVar) {
      return sideShadowColorCssVar;
    } else if (backgroundColorCssVar) {
      return backgroundColorCssVar;
    } else {
      return "#ffffff";
    }
  };

  const getSideShadowColorAsRGBString = () => {
    const sideShadowColor = getSideShadowColor();

    if (sideShadowColor.startsWith("var")) {
      const variableValue = sideShadowColor.replace(
        /^var\(--str-color-(.*?)\)$/,
        "--str-color-$1"
      );

      const hexColorValue = getCssVariableValue(variableValue);
      return `${hexToRgb(hexColorValue)[0]}, ${hexToRgb(hexColorValue)[1]}, ${
        hexToRgb(hexColorValue)[2]
      }`;
    } else {
      return `${hexToRgb(sideShadowColor)[0]}, ${
        hexToRgb(sideShadowColor)[1]
      }, ${hexToRgb(sideShadowColor)[2]}`;
    }
  };

  return (
    <>
      <ContentElementWrapper
        ceSettings={props.ceSettings}
        scopedClassName={IMAGETICKER_SCOPED_CLASS}
        name={IMAGETICKER_CE_NAME}
        content={props.content}
        isFirstElement={props.isFirstElement}
        isLastElement={props.isLastElement}
        position={props.position}
      >
        <Row
          className={clsx(IMAGETICKER_CE_NAME, IMAGETICKER_SCOPED_CLASS, "g-0")}
        >
          <Col xs="12">
            {tickerContent.content.length > 0 && props.content.cfgIsStatic ? (
              <ImageTickerStaticImages
                tickerContent={props.content}
                isMobile={props.isMobile}
                widthLimitSettings={{
                  mobile:
                    (props.ceSettings?.imageTickerHeight.tablet || 70) * 3,
                  desktop: (props.ceSettings?.imageTickerHeight.wqhd || 80) * 3,
                }}
              />
            ) : null}
            {tickerContent.content.length > 0 && !props.content.cfgIsStatic ? (
              <ImageTickerSlider
                ref={listContainer}
                isListBiggerThanContainer={isListBiggerThanContainer}
                sideShadowColor={getSideShadowColor()}
                sideShadowColorAsRGB={getSideShadowColorAsRGBString()}
              >
                <ImageTickerSliderList
                  ref={sliderList}
                  isListBiggerThanContainer={isListBiggerThanContainer}
                  animationSpeed={animationSpeed}
                  tickerContent={props.content}
                  onImageLoad={loadListWidth}
                  isMobile={props.isMobile}
                  widthLimitSettings={{
                    mobile:
                      (props.ceSettings?.imageTickerHeight.tablet || 70) * 3,
                    desktop:
                      (props.ceSettings?.imageTickerHeight.wqhd || 80) * 3,
                  }}
                />
                {isListBiggerThanContainer ? (
                  <ImageTickerSliderList
                    isListBiggerThanContainer={isListBiggerThanContainer}
                    animationSpeed={animationSpeed}
                    tickerContent={props.content}
                    onImageLoad={loadListWidth}
                    isMobile={props.isMobile}
                    widthLimitSettings={{
                      mobile:
                        (props.ceSettings?.imageTickerHeight.tablet || 70) * 3,
                      desktop:
                        (props.ceSettings?.imageTickerHeight.wqhd || 80) * 3,
                    }}
                  />
                ) : null}
              </ImageTickerSlider>
            ) : null}
            {props.content.content.length < 1 && editView ? (
              <div style={{ marginLeft: "4rem", marginRight: "4rem" }}>
                <CmsAddButton
                  title={tCms("addNewSlide")}
                  text={tCms("addNewSlide")}
                  onClick={() => {
                    dispatch(showNestedContentModal(props.position));
                  }}
                />
              </div>
            ) : null}
          </Col>
        </Row>
      </ContentElementWrapper>
      <ImageTickerContentElementGlobalStyle ceSettings={props.ceSettings} />
      <ImageTickerContentElementStyleScoped
        {...props}
        scopedSelector={IMAGETICKER_SCOPED_CLASS}
      />
    </>
  );
};

export default ImageTickerContentElement;
