import CmsImage from "@/components/cms/cmsImage/cmsImage";
import OptimizeStrapiImage, {
  OptimizeStrapiImageWidthLimits,
} from "@/components/cms/cmsImage/optimizeStrapiImage";
import { CEImageTicker } from "@/types/content-elements";
import { createHref } from "@/utils/util";
import clsx from "clsx";
import React, { CSSProperties, ForwardedRef, forwardRef } from "react";

interface ImageTickerSliderListProps {
  isListBiggerThanContainer: boolean;
  animationSpeed: CSSProperties;
  tickerContent: CEImageTicker;
  onImageLoad: React.ReactEventHandler<HTMLImageElement>;
  isMobile: boolean;
  widthLimitSettings?: OptimizeStrapiImageWidthLimits | number | undefined;
}

const ImageTickerSliderList = forwardRef(
  (props: ImageTickerSliderListProps, ref: ForwardedRef<HTMLUListElement>) => {
    return (
      <ul
        ref={ref}
        style={
          props.isListBiggerThanContainer ? props.animationSpeed : undefined
        }
        className={clsx(
          props.isListBiggerThanContainer && "animated",
          "image-ticker-list"
        )}
      >
        {props.tickerContent.content.map((imageSlide: any, index: number) => {
          return (
            <li key={index}>
              <div className="image-ticker-box">
                {imageSlide.imageUrl !== "" ? (
                  <a
                    href={createHref(
                      imageSlide.imageUrl,
                      imageSlide.linkAnchor,
                      imageSlide.linkGetParams
                    )}
                    rel={imageSlide.linkRel}
                    target={imageSlide.linkTarget}
                    hrefLang={imageSlide?.linkHrefLang}
                  >
                    {imageSlide.img ? (
                      <OptimizeStrapiImage
                        image={imageSlide.img}
                        isMobile={props.isMobile}
                        widthLimitSettings={props.widthLimitSettings}
                      >
                        <CmsImage
                          className="imageticker-slide-img"
                          onLoad={props.onImageLoad}
                          image={imageSlide.img}
                          width="auto"
                          height="100%"
                          objectFit="contain"
                        ></CmsImage>
                      </OptimizeStrapiImage>
                    ) : null}
                  </a>
                ) : (
                  <>
                    {imageSlide.img ? (
                      <OptimizeStrapiImage
                        image={imageSlide.img}
                        isMobile={props.isMobile}
                        widthLimitSettings={props.widthLimitSettings}
                      >
                        <CmsImage
                          className="imageticker-slide-img"
                          onLoad={props.onImageLoad}
                          image={imageSlide.img}
                          width="auto"
                          height="100%"
                          objectFit="contain"
                        ></CmsImage>
                      </OptimizeStrapiImage>
                    ) : null}
                  </>
                )}
              </div>
            </li>
          );
        })}
      </ul>
    );
  }
);

ImageTickerSliderList.displayName = "ImageTickerSliderList";

export default ImageTickerSliderList;
