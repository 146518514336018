import { ContentElementImageTickerStoreSetting } from "@/types/ceSettings/ceSettings";
import { IMAGETICKER_CE_NAME } from "./imageTickerContentElement";

interface ImageTickerContentElementGlobalStyleProps {
  ceSettings?: ContentElementImageTickerStoreSetting;
}

const ImageTickerContentElementGlobalStyle = (
  props: ImageTickerContentElementGlobalStyleProps
) => {
  return (
    <style jsx global>
      {`
        :global(.${IMAGETICKER_CE_NAME}) {
          @keyframes slider-endless {
            0% {
              transform: translateX(0);
            }
            100% {
              transform: translateX(-100%);
            }
          }

          :hover {
            .animated {
              -webkit-animation-play-state: paused;
              -moz-animation-play-state: paused;
              -o-animation-play-state: paused;
              animation-play-state: paused;
            }
          }

          .text {
            display: block;
          }
          .icon {
            display: none;
          }
          &:hover {
            .text {
              display: none;
            }
            .icon {
              display: block;
            }
          }
          .shadowLeft {
            left: 0;
            transform: translateX(-1px);
            background-image: linear-gradient(
              to right,
              rgba(255, 255, 255, 1) 10%,
              rgba(255, 255, 255, 0.7) 50%,
              rgba(255, 255, 255, 0) 100%
            );
          }
          .shadowRight {
            right: 0;
            transform: translateX(1px);
            background-image: linear-gradient(
              to left,
              rgba(255, 255, 255, 1) 10%,
              rgba(255, 255, 255, 0.7) 50%,
              rgba(255, 255, 255, 0) 100%
            );
          }
          .image-ticker-list {
            display: flex;
            position: relative;
            list-style: outside none none;
            margin: 0;
            padding: 0;
            li {
              display: inline-flex;
              margin: 0;
            }
            &.visibility-hidden {
              visibility: hidden;
            }
          }
          .animated {
            -webkit-animation: 60s linear 0s infinite normal running
              slider-endless; /* Safari 4+ */
            -moz-aanimation: 60s linear 0s infinite normal running
              slider-endless; /* Fx 5+ */
            -o-animation: 60s linear 0s infinite normal running slider-endless; /* Opera 12+ */
            animation: 60s linear 0s infinite normal running slider-endless;
          }

          .image-ticker-placeholder {
            overflow: hidden;
            margin: auto;
            display: block;
          }

          .imageticker-slide-img {
            max-width: unset;
            height: 100%;
            max-height: 100%;
            width: auto;
          }
        }
      `}
    </style>
  );
};

export default ImageTickerContentElementGlobalStyle;
