import { globalConfig } from "@/services/globalConfig/globalConfigService";
import { ImageTickerContentElementProps } from "./imageTickerContentElement";

interface ImageTickerContentElementStyleScopedProps
  extends ImageTickerContentElementProps {
  scopedSelector: string;
}

/**
 * This is the scoped style from a single CE instance
 * Here you can explicitly style one CE
 */
export default function ImageTickerContentElementStyleScoped(
  props: ImageTickerContentElementStyleScopedProps
) {
  const imageTickerHeight = {
    mobile: props.ceSettings?.imageTickerHeight?.mobile ?? 60,
    tablet: props.ceSettings?.imageTickerHeight?.tablet ?? 70,
    desktop: props.ceSettings?.imageTickerHeight?.desktop ?? 80,
    wqhd: props.ceSettings?.imageTickerHeight?.wqhd ?? 90,
  };

  const opacity = props.ceSettings?.opacity ?? "0.6";

  const imageMargin = ((props.ceSettings?.imageMarginRight ?? 0) as number) / 2;

  return (
    <style jsx global>{`
      :global(.${props.scopedSelector}) {
        .shadowRight,
        .shadowLeft {
          position: absolute;
          width: 5rem;
          z-index: 5;
        }

        .image-ticker-wrapper {
          display: flex;
          overflow: hidden;
          position: relative;
          &.center-list {
            justify-content: center;
          }
        }

        .image-ticker-box-static {
          overflow: hidden;
          display: inline-block;
          max-width: 100%;
          margin-left: ${imageMargin}px;
          margin-right: ${imageMargin}px;
          img {
            object-position: center center;
            opacity: ${opacity};
          }
        }
        .image-ticker-box {
          overflow: hidden;
          display: inline-block;
          margin-left: ${imageMargin}px;
          margin-right: ${imageMargin}px;
          img {
            object-position: center center;
            opacity: ${opacity};
          }
        }

        .image-ticker-box,
        .shadowRight,
        .shadowLeft,
        .image-ticker-box-static,
        .image-ticker-wrapper {
          height: ${imageTickerHeight.mobile}px;
        }

        @media (min-width: ${globalConfig?.responsive?.breakpoints?.mobile}px) {
          .image-ticker-box,
          .shadowRight,
          .shadowLeft,
          .image-ticker-box-static,
          .image-ticker-wrapper {
            height: ${imageTickerHeight.tablet}px;
          }
        }
        @media (min-width: ${globalConfig?.responsive?.breakpoints?.tablet}px) {
          .image-ticker-box,
          .shadowRight,
          .shadowLeft,
          .image-ticker-box-static,
          .image-ticker-wrapper {
            height: ${imageTickerHeight.desktop}px;
          }
        }
        @media (min-width: ${globalConfig?.responsive?.breakpoints
            ?.desktop}px) {
          .image-ticker-box,
          .shadowRight,
          .shadowLeft,
          .image-ticker-box-static,
          .image-ticker-wrapper {
            height: ${imageTickerHeight.wqhd}px;
          }
        }
      }
    `}</style>
  );
}
