import CmsImage from "@/components/cms/cmsImage/cmsImage";
import OptimizeStrapiImage, {
  OptimizeStrapiImageWidthLimits,
} from "@/components/cms/cmsImage/optimizeStrapiImage";
import { CEImageTicker } from "@/types/content-elements";
import { Col, Row } from "react-bootstrap";

interface ImageTickerStaticImagesProps {
  tickerContent: CEImageTicker;
  isMobile: boolean;
  widthLimitSettings?: OptimizeStrapiImageWidthLimits | number | undefined;
}

const ImageTickerStaticImages: React.FC<ImageTickerStaticImagesProps> = ({
  tickerContent,
  isMobile,
  widthLimitSettings,
}) => {
  return (
    <Col xs={12} md={10} className="offset-md-1 text-center">
      <Row className="px-3 px-md-0 justify-content-center edge-to-edge-row inner-row">
        {tickerContent.content.map((imageSlide, index) => {
          return (
            <Col key={index} className="col-auto px-0">
              <div className="image-ticker-box-static mb-2">
                {imageSlide.imageUrl !== "" ? (
                  <a
                    href={imageSlide.imageUrl + imageSlide?.linkGetParams || ""}
                    rel={imageSlide.linkRel}
                    target={imageSlide.linkTarget}
                    hrefLang={imageSlide?.linkHrefLang}
                  >
                    {imageSlide.img ? (
                      <OptimizeStrapiImage
                        image={imageSlide.img}
                        isMobile={isMobile}
                        widthLimitSettings={widthLimitSettings}
                      >
                        <CmsImage
                          image={imageSlide.img}
                          width="auto"
                          height="100%"
                          objectFit="contain"
                        ></CmsImage>
                      </OptimizeStrapiImage>
                    ) : null}
                  </a>
                ) : (
                  <>
                    {imageSlide.img ? (
                      <OptimizeStrapiImage
                        image={imageSlide.img}
                        isMobile={isMobile}
                        widthLimitSettings={widthLimitSettings}
                      >
                        <CmsImage
                          image={imageSlide.img}
                          width="auto"
                          height="100%"
                          objectFit="contain"
                        ></CmsImage>
                      </OptimizeStrapiImage>
                    ) : null}
                  </>
                )}
              </div>
            </Col>
          );
        })}
      </Row>
    </Col>
  );
};

export default ImageTickerStaticImages;
